import { Box, BoxProps, HStack, Heading, StyleProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { toRem } from '@/helpers/toRem';
import { removeTags } from '@/helpers/string';

export const TournamentInfoVariants = {
  CARD: 'card',
  BASE: 'base'
};

export const TournamentInfo = ({
  text,
  label,
  children,
  variant,
  withFullText,
  childrenWidth = 0,
  ...props
}: BoxProps & {
  text: string;
  label?: string;
  children: ReactNode;
  variant?: string;
  withFullText?: boolean;
  childrenWidth?: string | number;
}) => {
  let commonStyles: { [key: string]: StyleProps } = {
    heading: {
      fontWeight: '500',
      color: 'figma.neutral.400'
    },
    text: {
      fontSize: ['1.125rem', '1.5rem'], // 18, 24px
      lineHeight: [toRem(24), 'normal'],
      maxWidth: '15ch',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: 'figma.neutral.400'
    }
  };

  switch (variant) {
    case TournamentInfoVariants.BASE:
      commonStyles = {
        heading: {
          ...commonStyles.heading,
          fontSize: [toRem(14), toRem(18)],
          lineHeight: [toRem(20), toRem(26)]
        },
        text: {
          ...commonStyles.text,
          fontWeight: '500',
          maxWidth: withFullText ? 'none' : '15ch'
        }
      };

      if (!label) {
        commonStyles.text = {
          color: 'figma.grey.60',
          fontSize: [toRem(14), toRem(18)],
          lineHeight: [toRem(20), toRem(26)]
        };
      }
      break;

    case TournamentInfoVariants.CARD:
      commonStyles = {
        heading: {
          ...commonStyles.heading,
          fontSize: '1rem', // 18px
          lineHeight: '1rem' // 26px
        },
        text: {
          ...commonStyles.text,
          color: 'white',
          fontWeight: 500,
          // keep it for old navigator without @container query
          maxWidth: withFullText
            ? 'none'
            : ['10ch', '34ch', '13ch', '8ch', '13ch'],
          fontSize: '1rem', // 18px
          lineHeight: '1rem' // 26px
        }
      };
  }

  const textWithoutTags = removeTags(text);

  return (
    <HStack {...props}>
      {children}
      <Box maxWidth={`calc(100% - ${childrenWidth})`} ml="0!">
        <>
          {label ? (
            <Heading as="h4" {...commonStyles.heading}>
              {label}
            </Heading>
          ) : null}
          <Box
            data-tournament-info-text
            {...commonStyles.text}
            color={'white.100'}
            title={textWithoutTags}
            dangerouslySetInnerHTML={{
              __html:
                variant === TournamentInfoVariants.CARD ? textWithoutTags : text
            }}
          />
        </>
      </Box>
    </HStack>
  );
};
