import { TournamentIcon } from '@/theme/Icons';
import { Box, Heading, HeadingProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { toRem } from '@/helpers/toRem';

type TournamentsHeadingProps = HeadingProps & { children?: ReactNode };
export const TournamentsHeading = ({
  children,
  ...props
}: TournamentsHeadingProps) => {
  const { t } = useTranslation();

  return (
    <Heading
      data-testid="tournaments-title"
      fontSize={[toRem(18), toRem(24)]}
      lineHeight={[toRem(24), toRem(30)]}
      fontWeight="500"
      as="h2"
      mt={24}
      display={'flex'}
      gap={4}
      alignItems={'center'}
      {...props}
    >
      {/* initial ratio 33/38 */}
      <TournamentIcon width={26} height={30} />
      <Box textTransform={'uppercase'} as="span">
        {t('catalog:tournaments')}
      </Box>
      {children}
    </Heading>
  );
};
