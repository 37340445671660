import { Grid, GridProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TournamentsGridProps = GridProps & {
  children: ReactNode;
};
export const TournamentsGrid = ({
  children,
  ...props
}: TournamentsGridProps) => {
  return (
    <Grid
      data-testid="tournaments-grid"
      mt={10}
      templateColumns={[
        '1fr',
        '1fr 1fr',
        '1fr 1fr',
        '1fr 1fr 1fr'
        //'repeat(auto-fit, minmax(min(100%, 250px), 1fr))'
      ]}
      gap="5" // 40px
      w={'100%'}
      {...props}
    >
      {children}
    </Grid>
  );
};
