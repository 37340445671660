import {
  Link,
  Tag,
  Image,
  Box,
  LinkProps,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
import { default as NextLink } from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import {
  TournamentStatus,
  getCardLabel,
  getCardTagColorScheme,
  getTournamentDayLabel,
  getTournamentStatus,
  getTournamentTranslation,
  getTournamentUrl
} from '@/helpers/tournaments';
import { WalletIcon, ClockIcon, GiftIcon } from '@/theme/Icons';
import { LicenceType } from '@/utils/multiDomains';
import { TournamentInfo, TournamentInfoVariants } from './TournamentInfo';
import { TournamentType } from '../GamesCatalog/types';
import { toRem } from '@/helpers/toRem';
import { getFallbackLanguage } from '@/helpers/lang';

type TournamentCardProps = LinkProps & {
  tournament: TournamentType;
  license: LicenceType;
};
export const TournamentCard = ({
  tournament,
  license
}: TournamentCardProps) => {
  const { t, lang } = useTranslation();
  const { mediaUrls, minimumStake } = tournament;
  const translation = getTournamentTranslation({
    tournament,
    locale: getFallbackLanguage(lang)
  });

  if (!translation) {
    return null;
  }

  const { slug, title, prize, descriptionLead } = translation;

  const href = getTournamentUrl({
    id: tournament.id,
    slug: slug ?? '#',
    locale: lang,
    license
  });

  const labelDays = getTournamentDayLabel(tournament);
  const status = getTournamentStatus(tournament);

  return (
    <NextLink href={href} passHref>
      <Link
        data-testid={`tournament-card-${tournament.id}`}
        display="flex"
        flexDirection="column"
        position="relative"
        borderRadius="20px"
        overflow="hidden"
        bg="card.bg"
        _hover={{ textDecoration: 'none' }}
      >
        <Tag
          borderRadius="20px 0"
          fontWeight={500}
          fontSize={[toRem(15), toRem(18)]}
          lineHeight={[toRem(18), toRem(22)]}
          color="white"
          py={toRem(9)}
          px={toRem(19)}
          position={'absolute'}
          zIndex={1}
          boxShadow={0}
          bg={`tournament.status.${getCardTagColorScheme(tournament)}`}
        >
          {t(getCardLabel(tournament))}
        </Tag>
        <Image
          position={'relative'}
          zIndex={0}
          filter={`grayscale(${status === TournamentStatus.ENDED ? 1 : 0})`}
          srcSet={`${mediaUrls.card.xs} 287w, ${mediaUrls.card.md} 390w`}
          src={mediaUrls.card.xs}
          sizes={'(max-width: 500px) 287px, 390px'}
          alt={title}
          style={{ aspectRatio: '1.88' }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          borderBottomRadius="20px"
          border="1px solid"
          borderTop="0"
          borderColor="card.borderColor"
          px="8"
          pb="7"
          flexGrow={1}
          flexShrink={0}
          css={{
            containerType: 'inline-size',
            containerName: 'tournament-card',

            '@container tournament-card (max-width: 289px)': {
              '[data-tournament-info-container]': {
                '> [data-tournament-info-prize] [data-tournament-info-text]': {
                  maxWidth: '100%'
                },
                '> [data-tournament-info-min-bet]': {
                  marginTop: '.5rem'
                }
              }
            },

            '@container tournament-card (min-width: 290px)': {
              '[data-tournament-info-container]': {
                display: 'flex',

                '> [data-tournament-info-prize]': {
                  maxWidth: 'calc(100% - 100px - 16px)',
                  '[data-tournament-info-text]': {
                    maxWidth: '100%'
                  }
                },

                ' > [data-tournament-info-min-bet]': {
                  marginLeft: 'auto',
                  maxWidth: '100px',
                  justifyContent: 'flex-end'
                }
              }
            }
          }}
        >
          <HStack
            mt={4}
            visibility={
              status === TournamentStatus.ENDED ? 'hidden' : 'visible'
            }
          >
            <ClockIcon />

            <Text
              fontSize={[toRem(10), toRem(12)]}
              lineHeight={[toRem(12), toRem(15)]}
              color={'figma.grey.60'}
            >
              {t(labelDays.key, labelDays.options)}
            </Text>
          </HStack>

          <Heading
            as="h2"
            fontSize={[toRem(18), toRem(24)]}
            lineHeight={[toRem(24), toRem(30)]}
            fontWeight="500"
            mt={1.5}
          >
            {title}
          </Heading>
          <Text
            color="figma.neutral.600"
            mt="1.5!"
            style={{
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              overflow: 'hidden',
              maxWidth: '100%'
            }}
          >
            {descriptionLead}
          </Text>

          <HStack
            data-tournament-info-container
            mt="auto!"
            pt={4}
            gap="4"
            w="full"
            flexWrap={'wrap'}
            display={'block'}
          >
            <TournamentInfo
              w={'full'}
              data-tournament-info-prize
              variant={TournamentInfoVariants.CARD}
              text={prize || ''}
              color={'white'}
              mr={'auto'}
              childrenWidth={'40px'}
              className="info-prize"
            >
              <GiftIcon
                width={'1.5rem'}
                height={'1.5rem'}
                marginRight={['1rem']}
                opacity={0.4}
              />
            </TournamentInfo>
            <TournamentInfo
              w={'full'}
              data-tournament-info-min-bet
              variant={TournamentInfoVariants.CARD}
              text={`€${minimumStake / 100}`}
              ml={'0!'}
              childrenWidth={'40px'}
            >
              <WalletIcon
                width={'1.5rem'}
                height={'1.5rem'}
                marginRight={['1rem']}
                opacity={0.4}
              />
            </TournamentInfo>
          </HStack>
          {/* <Box mt={4}>Categories</Box> */}
        </Box>
      </Link>
    </NextLink>
  );
};
