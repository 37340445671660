import { Button, BoxProps, Text, Box } from '@chakra-ui/react';
import { TournamentCard } from './TournamentCard';
import { LicenceType, getFullUrlByLicense } from '@/utils/multiDomains';
import { useCatalog } from '@/context/Catalog';
import useTranslation from 'next-translate/useTranslation';
import { TournamentsGrid } from './TournamentsGrid';
import { TournamentsHeading } from './TournamentsHeading';
import { TournamentType } from '../GamesCatalog/types';
import { toRem } from '@/helpers/toRem';

type TournamentsHighLightProps = BoxProps;

export const TournamentsHighLight = ({
  ...props
}: TournamentsHighLightProps) => {
  const { t, lang } = useTranslation();

  const {
    licence,
    dataStore: { highlightedTournaments }
  } = useCatalog();

  const tournamentsArray: TournamentType[] = Object.values(
    highlightedTournaments || {}
  );
  return (
    <Box {...props} data-testid="tournaments-highlight">
      <TournamentsHeading
        mt={0}
        fontSize={[toRem(18)]}
        lineHeight={[toRem(18)]}
      >
        <Button
          href={`${getFullUrlByLicense(
            licence as LicenceType,
            lang
          )}/tournaments`}
          ml="auto"
          as="a"
          variant="outline"
          data-testid="tournaments-button-see-all"
          lineHeight={5}
          height={9}
        >
          {t('catalog:seeAll')}
        </Button>
      </TournamentsHeading>

      <TournamentsGrid mt={6}>
        {/* NO TOURNAMENTS */}
        {tournamentsArray.length === 0 && (
          <Text
            data-testid="tournaments-no-tournaments-label"
            fontSize={[toRem(18), toRem(24)]}
            lineHeight={[toRem(28), toRem(36)]}
            gridColumn={'1 / -1'}
            mt={16}
            align="center"
          >
            {t('tournament:noTournamentsHightlight')}
          </Text>
        )}

        {/* TOURNAMENTS LIST */}
        {tournamentsArray?.map((tournament, index) => (
          <TournamentCard
            key={index}
            license={licence as LicenceType}
            tournament={tournament}
          />
        ))}
      </TournamentsGrid>
    </Box>
  );
};
